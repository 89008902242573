import React from 'react'
import tw from 'twin.macro'

const Arrow = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple mb-1`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.2 12C18.5635 12 17.953 12.2529 17.5029 12.7029C17.0528 13.153 16.8 13.7635 16.8 14.4C16.8 15.0365 17.0528 15.647 17.5029 16.0971C17.953 16.5471 18.5635 16.8 19.2 16.8H32.6064L29.5032 19.9032C29.066 20.3558 28.8241 20.9621 28.8296 21.5914C28.835 22.2206 29.0874 22.8226 29.5324 23.2676C29.9774 23.7125 30.5794 23.965 31.2086 23.9704C31.8379 23.9759 32.4441 23.734 32.8968 23.2968L40.0968 16.0968C40.5467 15.6467 40.7995 15.0364 40.7995 14.4C40.7995 13.7636 40.5467 13.1533 40.0968 12.7032L32.8968 5.5032C32.6754 5.27398 32.4106 5.09114 32.1178 4.96536C31.8249 4.83957 31.51 4.77337 31.1913 4.7706C30.8727 4.76783 30.5566 4.82855 30.2617 4.94923C29.9667 5.0699 29.6988 5.24811 29.4734 5.47345C29.2481 5.69879 29.0699 5.96676 28.9492 6.26171C28.8285 6.55666 28.7678 6.87269 28.7706 7.19136C28.7734 7.51003 28.8396 7.82496 28.9653 8.11777C29.0911 8.41058 29.274 8.67541 29.5032 8.8968L32.6064 12H19.2ZM28.8 36C29.4365 36 30.047 35.7471 30.497 35.2971C30.9471 34.847 31.2 34.2365 31.2 33.6C31.2 32.9635 30.9471 32.353 30.497 31.9029C30.047 31.4529 29.4365 31.2 28.8 31.2H15.3936L18.4968 28.0968C18.726 27.8754 18.9088 27.6106 19.0346 27.3178C19.1604 27.025 19.2266 26.71 19.2294 26.3914C19.2322 26.0727 19.1714 25.7567 19.0508 25.4617C18.9301 25.1668 18.7519 24.8988 18.5265 24.6735C18.3012 24.4481 18.0332 24.2699 17.7383 24.1492C17.4433 24.0286 17.1273 23.9678 16.8086 23.9706C16.49 23.9734 16.175 24.0396 15.8822 24.1654C15.5894 24.2911 15.3246 24.474 15.1032 24.7032L7.90319 31.9032C7.45326 32.3533 7.2005 32.9636 7.2005 33.6C7.2005 34.2364 7.45326 34.8467 7.90319 35.2968L15.1032 42.4968C15.5558 42.934 16.1621 43.1759 16.7914 43.1704C17.4206 43.165 18.0226 42.9125 18.4676 42.4676C18.9125 42.0226 19.1649 41.4206 19.1704 40.7914C19.1759 40.1621 18.934 39.5558 18.4968 39.1032L15.3936 36H28.8Z"
            fill="currentColor"
        />
    </svg>
)

export default Arrow
