/* eslint-disable no-nested-ternary */
import React from 'react'
import tw from 'twin.macro'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColouredBand, ColoredBackgroundWithProps } from '../components/shared/landingPages/layoutComponents'
import Header from '../components/shared/landingPages/header'
import TopHighlights from '../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../components/shared/landingPages/highlightedFunctionalityOld'
import AdvancedFunctionality from '../components/shared/landingPages/advancedFunctionality'
import FinalCTA from '../components/shared/landingPages/finalCTA'
import Pages from '../images/invoicelp/pages'
import Arrow from '../images/invoicelp/arrow'
import Signet from '../images/invoicelp/signet'
import Graph from '../images/invoicelp/graph'
import laptopPng from '../images/invoicelp/headerImage.png'
import invoicePng from '../images/invoicelp/invoice.png'
import overviewPng from '../images/invoicelp/overview.png'
import completePng from '../images/invoicelp/complete.png'
import convincedPng from '../images/invoicelp/convinced.png'

const topHighlightData = {
    title: `Facturen maken met Tellow`,
    points: [
        {
            Icon: Pages,
            title: `Snel factureren`,
            body: `
        Maak facturen in een handomdraai. Gebruik standaard templates of ontwerp je eigen factuur.
        `,
        },
        {
            Icon: Arrow,
            title: `Ontvang snel je geld`,
            body: `
        Tellow geeft je een handig overzicht van betaalde en niet-betaalde facturen inclusief herinneringen.
        `,
        },
        {
            Icon: Signet,
            title: `Handig adresboek`,
            body: `
        Sla je contacten op en verifieer kvk-nummers.
        `,
        },
        {
            Icon: Graph,
            title: `Altijd je administratie op orde`,
            body: `
        Factuurnummers, btw-bedragen en totaalbedragen: alles automatisch berekend en ingevuld.
        `,
        },
    ],
}

const headerData = {
    announcer: `Je administratie op orde`,
    title: `Gratis facturen maken en versturen`,
    text: `Tellow is een online boekhoudtool voor zzp’ers en ondernemers. Scan bonnen, verstuur inkoop- en verkoopfacturen en regel eenvoudig je zaken bij de belastingdienst`,
    textCTA: `Gratis aanmelden`,
    headerImage: laptopPng,
}

const highlightedFunctionalityData = {
    title: `Maak nu gratis je facturen met Tellow`,
    firstPoint: `Professioneel`,
    firstDescription: `Gebruik je eigen logo en bedrijfskleuren.`,
    secondPoint: `Bespaar tijd`,
    secondDescription: `Vul je gegevens in en de factuur wordt automatisch opgesteld.`,
    firstStepImage: invoicePng,
}

const advancedFunctionalityData = {
    title: `Ontvang sneller je geld met Tellow`,
    subtitlePartOne: `Gebruik Tellow gratis via je desktop of onze handige app.`,
    subtitlePartTwo: `Binnen een paar klikken zijn je facturen verstuurt.`,
    firstAnnouncer: `Handig overzicht`,
    firstDescription: `Tellow geeft je een handig overzicht van je betaalde en niet-betaalde facturen. Ook sturen we je automatisch herinneringen. Zo loop je nooit geld mis en staat het geld snel op je rekening.`,
    getMoneyOneImage: overviewPng,
    secondAnnouncer: `Altijd compleet`,
    secondDescription: `De facturen die je verstuurt via Tellow zijn volledig en voldoen aan alle eisen. Kies zelf je design en wij vullen btw-bedragen, factuurnummers en ander zaken voor je in.`,
    getMoneyTwoImage: completePng,
}

const finalCTAData = {
    title: `Twijfel je nog?`,
    text: `Met Tellow Gratis verstuur je 3 facturen per maand gratis. Daarnaast heeft Tellow een tal van andere functionaliteiten zoals, bonnen scannen, automatische btw-aangifte, offertes maken, automatische bankkoppeling en nog veel meer.`,
    finalImage: convincedPng,
}

/**
 * @deprecated
 * Remove when marketing converts ads
 * to go to 'functies/facturen'.
 */
const FacturatieSoftware = () => (
    <Layout>
        {/* Page not indexed */}
        <Meta
            name="robots"
            indexed="noindex"
            path="/facturatie-software"
            title="Facturatie software | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        {/* First Component : Header (Main title and image) */}
        <ColoredBackgroundWithProps white>
            <RelativeContainer>
                <ComponentContainer>
                    <Header data={headerData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Purple Diagonal band  */}
        <ColouredBand purple />

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                {/* Second Component : Invoice Step (4 points) */}
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>

                {/* Third Component : First Invoice (Design of Invoice) */}
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        {/* Fourth Component : Get Money (I've got a lot of work to do) */}
        <RelativeContainer>
            <ComponentContainer>
                <AdvancedFunctionality data={advancedFunctionalityData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* Fifth Component : Convinced (Want to know more?) */}
        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer css={tw`my-0`}>
                    <FinalCTA data={finalCTAData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>
    </Layout>
)

export default FacturatieSoftware
