import React from 'react'
import tw from 'twin.macro'
import { RegisterButton } from '../../UI'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl mb-0 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-10`,
    bigTitle: tw`text-2xl lg:text-3xl font-bold items-start mb-4`,
    text: tw`text-base font-normal mb-5`,
    content: tw`flex flex-col h-auto items-start lg:pt-10 justify-center`,
    img: tw`hidden md:inline mx-auto my-0 p-0 filter drop-shadow-xl md:h-5/6`,
    imageContainer: tw`md:flex flex-col justify-center`,
}

const FinalCTA = ({ data }) => (
    <div css={style.wrapper}>
        <div css={style.grid}>
            <div css={style.content}>
                <h1 css={style.bigTitle}>{data.title}</h1>
                <p css={style.text}>{data.text}</p>
                <RegisterButton big purple style={{ textAlign: 'center' }}>
                    Gebruik Tellow gratis <span className="arrow">{'->'}</span>
                </RegisterButton>
            </div>
            <div css={style.imageContainer}>
                <img css={style.img} src={data.finalImage} alt="Nog niet overtuigd?" />
            </div>
        </div>
    </div>
)

export default FinalCTA
