import React from 'react'
import tw from 'twin.macro'
import { RegisterButton } from '../../UI'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl pb-4 mx-auto px-4 sm:px-6 lg:px-8`,
    grid: tw`grid grid-cols-1 md:grid-cols-2 gap-12`,
    pointGrid: tw`grid grid-cols-1 md:grid-cols-2 gap-4`,
    title: tw`text-3xl font-bold items-start mb-6`,
    text: tw`text-sm font-normal mb-8 ml-8`,
    point: tw`text-lg font-medium text-tellow-purple mb-0`,
    content: tw`flex flex-col h-auto items-start justify-center`,
    divide: tw`flex flex-row items-center mb-2`,
    img: tw`md:inline mx-auto my-0 p-0 filter drop-shadow-xl`,
    imageContainer: tw`flex flex-col justify-center`,
    svg: tw`h-6 w-6 fill-none! text-tellow-purple mr-2`,
}

const Check = () => (
    <svg css={style.svg} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="currentColor" fillOpacity="0.1" />
        <path d="M8 16L12 20L22 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const HighlightedFunctionality = ({ data }) => (
    <div css={style.wrapper}>
        <div css={style.grid}>
            <div css={style.imageContainer}>
                <img css={style.img} src={data.firstStepImage} alt="Facturen" />
            </div>
            <div css={style.content}>
                <h1 css={style.title}>{data.title}</h1>
                <div css={style.pointGrid}>
                    <div>
                        <div css={style.divide}>
                            <Check />
                            <p css={style.point}>{data.firstPoint}</p>
                        </div>
                        <p css={style.text}>{data.firstDescription}</p>
                    </div>
                    <div>
                        <div css={style.divide}>
                            <Check />
                            <p css={style.point}>{data.secondPoint}</p>
                        </div>
                        <p css={style.text}>{data.secondDescription}</p>
                    </div>
                </div>
                <RegisterButton big purple style={{ textAlign: 'center' }}>
                    Probeer het zelf <span className="arrow">{'->'}</span>
                </RegisterButton>
            </div>
        </div>
    </div>
)

export default HighlightedFunctionality
