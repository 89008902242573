import React from 'react'
import tw from 'twin.macro'

const Pages = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple mb-1`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.6 4.79999C20.3269 4.79999 19.1061 5.3057 18.2059 6.20587C17.3057 7.10605 16.8 8.32695 16.8 9.59999V28.8C16.8 30.073 17.3057 31.2939 18.2059 32.1941C19.1061 33.0943 20.3269 33.6 21.6 33.6H36C37.273 33.6 38.4939 33.0943 39.3941 32.1941C40.2943 31.2939 40.8 30.073 40.8 28.8V15.3936C40.7997 14.1207 40.2938 12.9 39.3936 12L33.6 6.20639C32.7 5.30615 31.4793 4.80026 30.2064 4.79999H21.6Z"
            fill="currentColor"
        />
        <path
            d="M7.20001 19.2C7.20001 17.927 7.70572 16.7061 8.6059 15.8059C9.50607 14.9057 10.727 14.4 12 14.4V38.4H31.2C31.2 39.6731 30.6943 40.894 29.7941 41.7941C28.8939 42.6943 27.673 43.2 26.4 43.2H12C10.727 43.2 9.50607 42.6943 8.6059 41.7941C7.70572 40.894 7.20001 39.6731 7.20001 38.4V19.2Z"
            fill="currentColor"
        />
    </svg>
)

export default Pages
